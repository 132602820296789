import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import store from './store'
import VueCookies from 'vue3-cookies'
import Toaster from '@meforma/vue-toaster'
import moment from 'moment-timezone'
import VueSocialSharing from 'vue-social-sharing'
import VueGtag from "vue-gtag";
import './registerServiceWorker'
import firebaseMessaging from "./firebase";


axios.defaults.baseURL = `${process.env.VUE_APP_API_BASE_URL || window.location.origin}/api`
axios.defaults.headers.common['Accept'] = 'application/json; charset=utf-8'
axios.defaults.headers.post['Content-Type'] = 'application/json'

axios.interceptors.request.use(req => {
  if (req.baseURL) {
    req.url = '/' + req.url
  }
  return req
})

axios.interceptors.response.use(res => {
  //console.log(res.data)
  // Captures user data returned on every authenticated request
  if (res.data._user) {
    store.commit('setUserData', res.data._user)
    // i18n.locale = res.data._user.ui_data['LOCALE'] || i18n.locale
    delete res.data._user
  }
  return res
}, (err) => {
  //console.log(err)
  if(err.response.status == 401)
  router.push('/')
  return Promise.reject(err)
})

moment.tz.setDefault("America/Santiago")
moment.defineLocale('es-dorapp', {
  parentLocale: 'es',
  longDateFormat: {
    L: "[Publicado el] DD/MM/YYYY [a las] hh:mm [hrs.]"
  }
})
moment.locale('es-dorapp')

const app = createApp(App).use(VueCookies, { expireTimes: "30d", sameSite: 'strict' })

app.provide('$axios', axios)
app.config.globalProperties.$axios = axios

app.config.globalProperties.$moment=moment


app.config.globalProperties.$messaging = firebaseMessaging

const authToken = app.config.globalProperties.$cookies.get('jwtA')
if (authToken) {
  axios.defaults.headers.common['Authorization'] = authToken
  axios.get('init').then(() => {
    app
      .use(store)
      .use(router)
      .use(VueGtag, {
        config: {
          id: process.env.VUE_APP_GOOGLE_ANALYTIC_ID
        }
      })
      .mount('#app')
  }).catch(error => {
    console.log(error)
    app.config.globalProperties.$cookies.remove('jwtA')
    delete axios.defaults.headers.common['Authorization']
    router.push('/')
    app
      .use(store)
      .use(router)
      .use(VueGtag, {
        config: {
          id: process.env.VUE_APP_GOOGLE_ANALYTIC_ID
        }
      })
      .mount('#app')
  })
} else {
  app
    .use(store)
    .use(router)
    .use(VueGtag, {
      config: {
        id: process.env.VUE_APP_GOOGLE_ANALYTIC_ID
      }
    })
    .mount('#app')
}

app.use(Toaster, {
  position: 'bottom'
})


app.use(VueSocialSharing)
