<template>
  <div :class="['modal-backdrop', position == 'bottom' ? 'modal-backdrop--bottom' : '']">
    <div class="modal modal--large">
      <div class="modal-container">
        <header v-if="$slots.title || $slots.description">
          <h3><slot name="title"></slot></h3>
          <p class="modal__description"><slot name="description"></slot></p>
        </header>
        <section>
          <slot name="content"></slot>
        </section>
        <section v-if="$slots.footer" class="form__actions">
          <slot name="footer"></slot>
        </section>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ModalDialog',
  props: {
    position: String
  }
}
</script>
