<template>
  <div class="modal-backdrop">
    <div class="modal modal--large">
      <div class="modal-container">
        <header>
          <h3>Notificaciones</h3>
          <p class="modal__description">Recibe notificaciones directo a tu dispositivo.</p>
        </header>
        <section class="form__actions">
          <template v-if="!is_iOS">
            <button v-if="!notificationStatus" type="button" class="btn btn--primary" @click="activateNotificationStatus">Activar</button>
            <button v-else type="button" class="btn btn--primary" @click="deactivateNotificationStatus">Desactivar</button>
          </template>
          <template v-else>
            De momento no podemos enviar notificaciones a dispositivos iOS.
          </template>
          <button class="btn btn--primary btn--text" @click.prevent="$emit('close')">Salir</button>
        </section>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NotificationDialog',
  props: {
    sharing: Object
  },
  data() {
    return {
      notificationStatus: null,
      loaded: false
    }
  },
  async created(){
    const res = await this.$axios.post('users/get-notification-status')
    if(res.status == 200){
      this.notificationStatus = res.data
      this.loaded = true
    }
  },
  methods:{
    activateNotificationStatus(){
      const vue = this
      Notification.requestPermission().then(res => {
        if(res === 'granted')
          vue.$axios.post('users/update-notifications', {status: true}).then(res2 => {
            if(res2.status == 200){
              vue.$toast.success('Las notificaciones han sido activadas exitosamente')
              vue.notificationStatus = true
              vue.$emit('close')
            }
            else
              vue.$toast.error('Ocurrió un error al desactivar las notificaciones')
          })
        else
          vue.$toast.error('No se pudieron activar las notificaciones, verifica que la aplicación tenga los permisos correspondientes')
        
      })
    },
    deactivateNotificationStatus(){
      this.$axios.post('users/update-notifications', {status: false}).then(res2 => {
        if(res2.status == 200){
          this.$toast.success('Las notificaciones han sido desactivadas exitosamente')
          this.notificationStatus = false
          this.$emit('close')
        }
        else
          this.$toast.error('Ocurrió un error al desactivar las notificaciones')
      })
    }
  },
  computed: {
    is_iOS(){
      return /iPhone|iPod|iPad/i.test(navigator.userAgent)
    }
  }
}
</script>
