<template>
  <div class="search">
    <img class="ico-search" src="@/assets/images/icons/ico_search--bl.svg">
    <div class="form__group" v-click-outside="clearSearch">
      <input class="form__input" v-model="inputText" type="text" placeholder="Busca en Dorapp"
      @keydown.esc="clearSearch"
      @focus="hasFocus = true"
      v-debounce:700ms="search"/>
      <template v-if="inputText && hasFocus">
        <div class="list" v-if="loading">
          <div class="list__item" v-for="i in 5" :key="i">
            <Loader viewBox="0 0 300 20">
              <rect x="15" y="3" rx="0" ry="0" width="90%" height="15" />
            </Loader>
          </div>
        </div>
        <div class="list" v-else>
          <template v-for="item in filteredList" :key="item">
            <router-link :to="getRoute(item)" class="list__item">
              <span :title="item.title">
                {{normalize(item.title) }}
              </span>
              <span :class="['tag tag--primary post__tag', item.category == 'Áreas de interés' ? 'AdI' : item.category ]">{{item.category}}</span>
            </router-link>
          </template>
          
          <router-link v-if="seeMore" :to="getRoute(filteredList[0], true)" class="list__item list__item--more">
            <span class="text-alt">Ver más</span>
          </router-link>
          <div class="item error" v-if="inputText && !filteredList.length && !loading">
            <p>Sin resultados</p>
          </div>
        </div>
      </template>
    </div>
  </div>
  <div class="extra-content--areas-and-causes--search mobile-only">
    <template v-if="popularAreas.length > 0">
      <h5 class="extra-content__title">Áreas de interés</h5>
      <div class="extra-content__list">
        <router-link class="grid grid-2 extra-content__list-item" v-for="(area, index) in popularAreas" :key="index" :to="{name: 'Filter' , params:{ filterType: `areas-de-interes` , filterId: area.id }}" @click="clearSearch">
          <div class="dot"></div>
          <p>{{ area.name }}</p>
          <div class="text-right">
            <span class="tag tag--primary post__tag">{{ area.total }}</span>
          </div>
        </router-link>
      </div>
    </template>
    <br>
    <template v-if="popularCauses.length > 0">
      <h5 class="extra-content__title">Causas</h5>
      <div class="extra-content__list">
        <router-link class="grid grid-2 extra-content__list-item" v-for="(cause, index) in popularCauses" :key="index" :to="{name: 'Filter' , params:{ filterType: `causas` , filterId: cause.id }}" @click="clearSearch">
          <div class="dot"></div>
          <p>
            {{ cause.name }}
          </p>
          <div class="text-right">
            <span class="tag tag--primary post__tag">{{ cause.total }}</span>
          </div>
        </router-link>
      </div>
    </template>
  </div>
</template>


<script>
import { ContentLoader as Loader } from 'vue-content-loader'
import { vue3Debounce } from 'vue-debounce'
import vClickOutside from 'click-outside-vue3'


export default {
  directives: {
    debounce: vue3Debounce({ lock: true }),
    clickOutside: vClickOutside.directive
  },
  name: "SearchBar",
  components: { Loader },
  data() {
    return {
      inputText: "",
      tags: [],
      causes: [],
      areas: [],
      posts: [],
      people: [],
      loading: false,
      filteredList: [],
      valueChanged: false,
      hasFocus: false,
      seeMore: false,
      popularAreas: [],
      popularCauses: []
    }
  },
  methods: {
    async search() {
      if(this.inputText && this.valueChanged){
        const res = await this.$axios.post('search/find', {keywords: this.inputText})
        const results = res.data
        if(results.length > 0){
          const filteredResults = []
          let total = 18
          this.seeMore = results.length > total
          if(!this.seeMore)
            total = results.length
          const entries = [
            results.filter(n => n.route == "qna"), 
            results.filter(n => n.route == "profile"),
            results.filter(n => n.route == "etiquetas"), 
            results.filter(n => n.route == "causas"), 
            results.filter(n => n.route == "areas-de-interes"), 
            results.filter(n => n.route == "eventos")
          ]
          //const types = ["etiquetas", "causas", "areas-de-interes", "qna", "profile"]
          for(let i = 0; filteredResults.length < total; i++){
            for(let j = 0; j < 18 / 3; j++)
              if(entries[j][i]) filteredResults.push(entries[j][i])
          }
          this.filteredList = filteredResults.sort((a,b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0))
        }
        else{
          this.filteredList = []
          this.seeMore = false
        }
      }
      else
        this.filteredList = []
      this.loading = false
      return null
    },
    normalize(name){
      const n = 60
      if(name.length > n)
        name = name.slice(0, n) + '...'
      return name
    },
    clearSearch(){
      this.hasFocus = false
      this.inputText = ''
      this.valueChanged = false
      this.loading = false
      this.filteredList = []
    },
    getRoute(item, advanced = false){
      if(advanced)
        return {name: 'Search', params: {filterType: ['profile', 'qna'].includes(item.route) ? (item.route == 'profile' ? 'personas' : 'preguntas') : item.route, keywords: this.inputText}}
      if(item.route == 'qna')
        return {name: 'Post', params: {id: item.id}}
      if(item.route == 'profile')
        return {name: 'Profile', params: {id: item.id}}
      if(item.route == 'eventos')
        return {name: 'Event', params: {id: item.id}}
      return {name: 'Filter', params: {filterType: item.route, filterId: item.id}
      }
    },
    redirect(to){
      this.$router.push(to)
      this.clearSearch()
    }
  },
  watch: {
    inputText(newVal, oldVal){
      this.loading = true
      this.hasFocus = true
      this.valueChanged = newVal != oldVal
    },
    $route() {
      this.clearSearch()
    }
  },
  async mounted() {
    const areas = (await this.$axios.get('Areas')).data
    const causas = (await this.$axios.get('Causas')).data
    this.popularAreas = areas.filter(item => item).sort((a , b) => {return b.total - a.total})
    this.popularCauses = causas.filter(item => item).sort((a , b) => {return b.total - a.total})
  },
}
</script>