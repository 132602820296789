<template>
  <div class="dropdown">
    <div class="dropdown__button" @click.prevent="toggle">
      <slot name="button"></slot>
    </div>
    <div class="dropdown__content" v-show="isOpen">
      <div v-if="dropdown === 'notification'" class="dropdown__options">
        <slot @click.prevent="toggle" name="options"></slot>
      </div>
      <div v-else-if="dropdown === 'search'" class="dropdown__options">
        <div class="close-search mobile-only">
          <button 
            class="btn btn--primary btn--outline btn--xs" 
            @click="toggle"
          >
            Cerrar
          </button>
        </div>
        <slot name="options"></slot>
      </div>
      <div v-else @click.prevent="toggle" class="dropdown__options">
        <slot name="options"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropdownMenu',
  props:{
    dropdown: String
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.isOpen = false
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.close)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.close)
  }
}
</script>