import { createWebHistory, createRouter } from "vue-router";
import store from '@/store'
import { createToaster } from "@meforma/vue-toaster";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

const toaster = createToaster({position: 'bottom'});

const routes = [
  {
    path: '/',
    name: 'Caracteristicas',
    meta: {
      title: '¿Qué es Dorapp?',
      section: 'External'
    },
    component: () => import('@/views/landingPageView.vue'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    beforeEnter: (to, from, next) => checkNotAuthenticated(to, from, next),
    meta: {title: 'Iniciar sesión'}
  }, 
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next),
    meta: {section: 'home', title: 'Inicio'}
  }, 
  {
    path: '/administradores',
    name: 'AdminIndex',
    component: () => import('@/views/Admins/Index.vue'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [1]),
    meta: {title: 'Administradores', private: true}
  },
  {
    path: '/sistema/grupos-y-permisos',
    name: 'GroupsAndPermissions',
    component: () => import('@/views/GroupsAndPermissionsView.vue'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [1]),
    meta: {section: 'sistema', subsection: 'grupos-y-permisos', title: 'Sistema', private: true}
  },
  {
    path: '/sistema/grupos-y-permisos/grupo/:groupId',
    name: 'Group',
    component: () => import('@/views/GroupView.vue'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [1]),
    meta: {section: 'sistema', subsection: 'grupos-y-permisos', title: 'Sistema', private: true}
  },
  {
    path: '/sistema/causas',
    name: 'Causes',
    meta: {
      title: 'Causas',
      section: 'sistema', 
      subsection: 'causas',
      private: true
    },
    component: () => import('@/views/CausesView.vue'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [1])
  },
  {
    path: '/sistema/areas-de-interes',
    name: 'AreasOfInterest',
    meta: {
      title: 'Áreas de interés',
      section: 'sistema', 
      subsection: 'areas-de-interes',
      private: true
    },
    component: () => import('@/views/AreasOfInterestView.vue'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [1])
  },
  {
    path: '/sistema/etiquetas',
    name: 'Tags',
    meta: {
      title: 'Etiquetas',
      section: 'sistema', 
      subsection: 'etiquetas',
      private: true
    },
    component: () => import('@/views/TagsView.vue'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [1])
  },
  {
    path: '/usuarios',
    name: 'UserIndex',
    component: () => import('@/views/Users/Index.vue'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [1]),
    meta: { private: true }
  },
  {
    path: '/perfil',
    name: 'Profile',
    meta: {
      title: 'Perfil',
      private: true
    },
    component: () => import('@/views/ProfileView.vue'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [1, 2])
  },
  {
    path: '/mi-perfil',
    name: 'MyProfile',
    meta: {
      title: 'Mi perfil',
      private: true
    },
    component: () => import('@/views/MyProfileView.vue'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [1, 2])
  },
  {
    path: '/mi-perfil/editar',
    name: 'MyProfileEdit',
    meta: {
      title: 'Editar mi perfil',
      private: true
    },
    component: () => import('@/views/ProfileEditView.vue'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [1, 2])
  },
  {
    path: '/perfil/:id',
    name: 'Profile',
    component: () => import('@/views/ProfileView.vue'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next)
  },
  {
    path: '/perfil/editar/:id',
    name: 'EditProfile',
    component: () => import('@/views/ProfileEditView.vue'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [1, 2]),
    meta: { private: true }
  },
  {
    path: '/nueva-publicacion',
    name: 'NewPost',
    meta: {
      title: 'Nueva publicación/pregunta',
      private: true
    },
    component: () => import('@/views/NewPostView.vue'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [1, 2])
  },
  {
    path: '/publicacion/:id',
    name: 'Post',
    component: () => import('@/views/PostView.vue'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next)
  },
  {
    path: '/filtro/:filterType/:filterId',
    name: 'Filter',
    component: () => import('@/views/FilteredPostsView.vue'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next),
  },
  {
    path: '/busqueda/:filterType/:keywords',
    name: 'Search',
    component: () => import('@/views/SearchResultsView.vue'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next),
  },
  {
    path: '/eventos',
    name: 'Events',
    meta: {
      title: 'Eventos',
      section: 'events'
    },
    component: () => import('@/views/EventsView.vue'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next)
  }, 
  {
    path: '/evento/:id',
    name: 'Event',
    component: () => import('@/views/EventView.vue'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next),
    meta: {section: 'events'}
  }, 
  {
    path: '/evento/nuevo-evento',
    name: 'NewEvent',
    meta: {
      title: 'Nuevo Evento',
      section: 'events',
      private: true
    },
    component: () => import('@/views/NewEventView.vue'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [1 , 2]),
  },
  {
    path: '/eventos/editar/:id',
    name: 'EditEvent',
    meta: {
      title: 'Editar Evento',
      section: 'events',
      private: true
    },
    component: () => import('@/views/EditEventView.vue'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [1 , 2])
  },
  {
    path: '/notificaciones',
    name: 'NotificationCenter',
    meta: {
      title: 'Notificaciones',
      section: 'notifications',
      private: true
    },
    component: () => import('@/views/NotificationCenterView.vue'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [1 , 2])
  },
  
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { 
      el: '.layout',
      top: -10
    };
  }
});



router.beforeEach(async (to, from, next) => {
  //console.log(`Navigating from ${from.name} to ${to.name}`)
  if(to.meta.title)
    document.title = `${to.meta.title} - Dorapp`
  if (to.query.logout) {
    store.commit('setUserData', {})
    cookies.remove('jwtA')
    //location.reload()
    if(from.meta.private)
      next('/')
    next(to.path)
  } else {
    if(store.state.user.basic_setup_finished == false && to.name != 'MyProfileEdit'){
      const aux = {}
      toaster.error('Debes agregar tus datos para continuar utilizando la plataforma')
      aux.name = 'MyProfileEdit'
      next(aux)
    }else{
      
      /*
    if (store.state.mediaQuery != null && store.state.user.id) {
      let shouldExpand = ['xl', 'xxl'].includes(store.state.mediaQuery)

       if (store.state.user.ui_data['EXPANDED-SIDEBAR'] !== shouldExpand) {
        let res = await axios.patch(`users/${store.state.user.id}`, { ui_data: { 'EXPANDED-SIDEBAR': shouldExpand } })
        store.commit('setUserData', res.data.user)
      }
    }*/
      next()
    }
  }
})

const checkAuthenticated = (to, from, next, id) => {
  if(id){
    if (store.state.user.id && id.includes(store.state.user.role_id)){
      next()
    }
    else
      next('/home')
  }
  else
    next()
}

const checkNotAuthenticated = (to, from, next) => {
  if (!store.state.user.id) {
    next()
  } else {
    next({ name: 'Home' })
  }
}

export default router;