<template>
  <div :class="['layout', $route.name != 'Login' ? '' : 'layout--auth']">
    <div class="top-nav" v-if="$route.name != 'Login'" >
      <div class="top-nav__side top-nav__side--left">
        <router-link class="logo-container" :to="{name: 'Home'}"><img class="logo" src="@/assets/images/logo_dorapp--wh.svg" alt="En la imagen el logo de Dorapp"></router-link>
      </div>
      <div class="top-nav__side top-nav__side--center">
        <div class="desktop-search">
          <SearchBar />
        </div>
      </div>
      <div class="top-nav__side top-nav__side--right">
        <DropdownMenu class="mobile-search" :dropdown="'search'">
          <template #button>
            <button class="btn btn--icon btn--circle">
              <img class="icon" src="@/assets/images/icons/ico_search--white.svg">
            </button>
          </template>
          <template #options>
            <SearchBar />
          </template>
        </DropdownMenu>

        <template v-if="$store.state.user.id">
          <button class="btn btn--icon btn--circle mobile-notification" @click.prevent="seeAll()"><img class="icon" src="@/assets/images/icons/ico_bell--wh.svg"><div v-if="notifications.filter(n => !n.seen).length > 0" class="notification-alert"></div></button>

          <DropdownMenu class="notification-dropdown desktop-notification" ref="dropdown" :dropdown="'notification'" >
            <template #button>
              <button class="btn btn--icon btn--circle"><img class="icon" src="@/assets/images/icons/ico_bell--wh.svg"><div v-if="notifications.filter(n => !n.seen).length > 0" class="notification-alert"></div></button>
            </template>
            <template class="notifications" #options v-if="notifications">
              <div class="notification-titles">
                <h4>Notificaciones</h4>
                <p class="caption mark-as-read" @click.prevent="markAllAsRead"><strong>Marcar todo como leído</strong></p>
              </div>
              <p class="caption" v-if="notifications.find(n => $moment.utc(n.createdAt).isSame($moment.utc(), 'day'))"><strong>Hoy</strong></p>

              <template v-for="notification in notifications.slice(0, 5)" :key="notification.id">

                <p class="caption" v-if="$moment.utc(notification.createdAt).isBefore($moment.utc(), 'day') && 
                notifications.filter(n => $moment.utc(n.createdAt).isBefore($moment.utc(), 'day')).indexOf(notification) == 0">
                  <strong>Antes de hoy</strong>
                </p>
                <p>
                  <!-- <span v-if="notification.seen">Leída </span>
                  <span v-else>No leída </span> -->
                  <span :class="['dot', notification.seen ? 'dot--inactive' : '' ]"></span>
                  <span :class="[notification.seen ? 'notification--inactive' : '' ]">
                    <span
                      class="notification-item"
                      v-html="formatNotification(notification)"
                      @click="markAsRead(notification)"
                      >
                    </span>
                    {{$moment.utc(notification.createdAt).fromNow()}}
                  </span>
                </p>
              </template>
              <div class="form__actions text-right">
                <p class="btn btn--primary btn--link" @click="seeAll()">Ver todas</p>
              </div>
            </template>
          </DropdownMenu>
          <div class="desktop-menu">
            <router-link :to="{name: 'NewPost'}" class="btn btn--primary"><img class="icon" src="@/assets/images/icons/ico_add--or.svg"> Nueva publicación</router-link>
            <router-link class="user-profile" :to="{ name: 'MyProfile' , params : { id : $store.state.user.id} }">
              <div class="user__image" v-if="$store.state.user.profile_image">
                <img referrerpolicy="no-referrer" :src="$store.state.user.profile_image">
              </div>
              <div v-else class="user__image">
                <img src="@/assets/images/avatar_default.png">
              </div>
              <div class="user-info">
                <h5>{{ $store.state.user.name }} {{$store.state.user.lastnameA}}</h5>
              </div>
            </router-link>
          </div>


          <DropdownMenu class="mobile-menu">
            <template #button>
              <button class="btn btn--icon btn--circle">
                <img class="icon" src="@/assets/images/icons/ico_menu--white.svg">
              </button>
            </template>
            <template #options>
              <div class="mobile-menu__profile">
                <div class="mobile-menu__profile-data">
                  <div class="user__image" v-if="$store.state.user.profile_image">
                    <img referrerpolicy="no-referrer" :src="$store.state.user.profile_image">
                  </div>
                  <div v-else class="user__image">
                    <img src="@/assets/images/avatar_default.png">
                  </div>
                  <div class="user-info">
                    <h5>{{ $store.state.user.name }} {{$store.state.user.lastnameA}}</h5>
                  </div>
                </div>
                <router-link class="btn btn--primary btn--xs" :to="{ name: 'MyProfile' , params : { id : $store.state.user.id} }">Ver perfil</router-link>
              </div>
              <router-link class="dropdown__option" :to="{ name: 'Home' }">
                <img class="dropdown__icon" src="@/assets/images/icons/ico_home--bl.svg">
                Inicio
              </router-link>
              <router-link class="dropdown__option" :to="{ name: 'Events' }">
                <img class="dropdown__icon" src="@/assets/images/icons/ico_calendar--bl.svg">
                Calendario
              </router-link>
              <a href="#" class="dropdown__option" @click.prevent="showNotificationDialog = true">
                <img class="dropdown__icon" src="@/assets/images/icons/ico_settings--bl.svg">
                Notificaciones
              </a>
              <a href="#" class="dropdown__option danger" @click.prevent="logout()">
                <img class="dropdown__icon" src="@/assets/images/icons/ico_logout--re.svg">
                Cerrar sesión
              </a>
            </template>
          </DropdownMenu>
        </template>
        <template v-else>
          <div class="desktop-menu">
            <div class="text-right">
              <router-link :to="{name: 'Login'}" class="btn btn--primary">Iniciar sesión</router-link>
            </div>
          </div>
          <div>
            <DropdownMenu class="mobile-menu">
              <template #button>
                <button class="btn btn--icon btn--circle">
                  <img class="icon" src="@/assets/images/icons/ico_menu--white.svg">
                </button>
              </template>
              <template #options>
                <router-link class="dropdown__option" :to="{ name: 'Home' }">
                  <img class="dropdown__icon" src="@/assets/images/icons/ico_home--bl.svg">
                  Inicio
                </router-link>
                <router-link class="dropdown__option" :to="{ name: 'Events' }">
                  <img class="dropdown__icon" src="@/assets/images/icons/ico_calendar--bl.svg">
                  Calendario
                </router-link>
                <div class="dropdown__option">
                  <router-link :to="{name: 'Login'}" class="btn btn--primary btn--block">Iniciar sesión</router-link>
                </div>
              </template>
            </DropdownMenu>
          </div>
        </template>
      </div>
    </div>
    <div class="auth-top-nav" v-else>
      <div class="logo-container">
        <!-- <img class="logo" src="@/assets/images/logo_dorapp--or.svg" alt="En la imagen el logo de Dorapp"> -->
      </div>
    </div>
    <div class="content-wrapper">
      <router-view /> 
    </div>
  </div>
  <NotificationDialog v-if="$store.state.user.id && showNotificationDialog" @close="showNotificationDialog = false"/>
  <modal-dialog :position="'bottom'" v-if="iOSInstallInstructions" @close="iOSInstallInstructions = false">
    <template #title>
      <h4 class="text-center">Instalación iOS</h4>
    </template>
    <template #content>
      <p>Instala la aplicación desde Safari para un acceso más rápido.</p>
      <ol>
        <li>Haz tap en el icono <img class="ios-share-icon" src="@/assets/images/icons/ico_ios-share.svg">.</li>
        <li>Haz tap en <strong>Agregar a Inicio</strong> y luego en <strong>Agregar</strong>.</li>
      </ol>
    </template>
    <template #footer>
      <div class="form__actions form__actions--center">
        <button class="btn btn--primary btn--link" type="button" @click.prevent="iOSInstallInstructions = false">Entendido</button>
      </div>
    </template>
  </modal-dialog>
</template>

<script>
import SearchBar from '@/components/SearchBar.vue'
import DropdownMenu from '@/components/DropdownMenu.vue'
import NotificationDialog from '@/components/NotificationsDialog.vue'
import { getToken, onMessage } from "firebase/messaging"
import ModalDialog from '@/components/ModalDialog.vue'

export default {
  name: "App",
  components:{
    SearchBar,
    DropdownMenu,
    NotificationDialog,
    ModalDialog
  },
  data() {
    return {
      notifications: [],
      oldTotal: 0,
      newTotal: 0,
      checkNotifications: false,
      interval: null,
      currentToken: null,
      showNotificationDialog: false,
      iOSInstallInstructions: false
    }
  },
  methods: {
    async logout() {
      if(this.currentToken)
        await this.$axios.post('users/clear-token', {token: this.currentToken})
      this.$router.push({ path: this.$route.path, query: { logout: true } })
    },
    reNew() {
      this.$axios.post("notification/total").then(res0 => {
        this.oldTotal = res0.data
          if(this.oldTotal != this.newTotal || this.checkNotifications) {
            this.checkNotifications = false
            let previous = null
            this.$axios.post("notification/get").then(res => {
              this.newTotal = res.data.total
              let preFilteredNotifications = []
              const notifications = res.data.notifications
              if(notifications.length > 0){
                preFilteredNotifications.push(notifications[0])
                  previous = notifications[0]
                  notifications.shift()
                  notifications.forEach((e) => {
                    previous.from = Array.isArray(previous.from) ? previous.from : [previous.from]
                    previous.id = Array.isArray(previous.id) ? previous.id : [previous.id]
                    if(previous.route == e.route && previous.route_id == e.route_id && previous.type == e.type) {
                      if(!(previous.from.includes(e.from))){
                        previous.from.push(e.from)
                        previous.id.push(e.id)
                      }
                    }
                    else
                      preFilteredNotifications.push(e)
                    
                    previous = preFilteredNotifications[preFilteredNotifications.length - 1]
                  })
                  previous.from = Array.isArray(previous.from) ? previous.from : [previous.from]
                  previous.id = Array.isArray(previous.id) ? previous.id : [previous.id]
                  if(this.is_iOS && this.notifications.length != 0){
                    const vueRouter = this.$router
                    this.$toast.success(this.formatNotification(preFilteredNotifications[0]),
                    {
                      duration: 6000,
                      onClick: function(){
                        vueRouter.push(
                          {
                            name: previous.route,
                            params: { id: previous.route_id }
                          }
                        )
                      }
                    })
                  }
                }
              this.notifications = preFilteredNotifications
            })
          }
      })
      if(!this.is_iOS)
        this.tokenHandler()
    },
    addCommasAndAnd(list) {
      list = list.map(e => { return `<span class="strong">${e}</span>`})
      if (list.length < 3)
        return list.join(' y ')
      if (list.length >= 3){
        let resto = list.length - 2
        list = list.slice(0, 2)
        if(resto == 1)
          list.push(` y a <span class="strong">1</span> persona más`)
        else
          list.push(` y a ${resto} personas más`)
      }
      return `${list.slice(0, - 1).join(', ')}${list[list.length - 1]}`;
    },
    formatNotification(notification) {
      let text, authors = "";
      if (notification.from_id === -1) {
        text = notification.message_s
        authors = "Se"
      } else {
        text = notification.from.length > 1 ? notification.message_p : notification.message_s
        authors = this.addCommasAndAnd(notification.from)
      }
      let title = `<span class="strong">${notification.title}</span>`
      return text.replace('NAME_PLACEHOLDER', authors).replace('POST_PLACEHOLDER', title).replace('EVENT_PLACEHOLDER', title).replace('COMMENT_PLACEHOLDER', title)},
    markAsRead(notification)
    {
      if(!notification.seen)
        this.$axios.post("notification/markAsRead", {id: notification.id}).then(res => {
          if(res.status == 200)
            notification.seen = true
          this.$refs.dropdown.toggle()
          this.$router.push({name: notification.route, params: {id: notification.route_id}})
        })
      else{
        this.$refs.dropdown.toggle()
        this.$router.push({name: notification.route, params: {id: notification.route_id}})
      }
    },
    startInterval(){
      if(this.$store.state.user.id){
        this.interval = setInterval(() =>{
          this.reNew()
        }, 60000)
      }
    },
    markAllAsRead(){
      this.$axios.post("notification/markAllAsRead").then(res => {
        if(res.status == 200)
          this.notifications.forEach(e => {
            e.seen = true
          })
      })
    },
    seeAll(){
      this.$refs.dropdown.toggle()
      this.$router.push({name: 'NotificationCenter'})
    },
    updateToken(token){
      this.$store.commit('setUserToken', token)
      this.$axios.post('users/set-token', {token: token})
    },
    tokenHandler(){
      getToken(this.$messaging, {
          vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY,
      }).then((currentToken) => {
        if (currentToken) {
          if(this.currentToken !== currentToken){
            if(currentToken != this.$store.state.user.notification_token)
              this.updateToken(currentToken)
            this.currentToken = currentToken
            const router = this.$router
            onMessage(this.$messaging, (payload) => {
              payload.icon = '/icons/faviconicon-32.png'
              this.$toast.success(payload.notification.title,
              {
                duration: 6000,
                onClick: function(){
                  router.push(
                    {
                      name: payload.data["gcm.notification.click_action_route_name"],
                      params: {id: payload.data["gcm.notification.click_action_element_id"]}
                    }
                  )
                }})
            })
          }
        }
      }).catch((err) => {
        console.log("An error occurred while retrieving token.", err)
      })
    }
  },
  async mounted() {
    if(this.$store.state.user.id){
      this.tokenHandler()
      this.reNew()
    }
    
  },
  created(){
    if(this.$store.state.user.id){
      this.startInterval()
    }

    const isInStandaloneMode = 'standalone' in window.navigator && window.navigator.standalone
    
    if(this.is_iOS && !isInStandaloneMode) {
      this.iOSInstallInstructions = true
    }
  },
  unmounted(){
    if(this.interval)
      clearInterval(this.interval)
  },
  watch: {
    '$store.state.user.id': function(newValue) {
      if(newValue == undefined){
        this.notifications = []    
        this.oldTotal = 0
        this.newTotal = 0
        clearInterval(this.interval)
      }else{
        this.checkNotifications = true
        this.reNew()
        this.startInterval()
      }
    }
  },
  computed: {
    is_iOS(){
      return /iPhone|iPod|iPad/i.test(navigator.userAgent)
    }
  }
}
</script>

<style lang="sass">
  @use '@/assets/styles/main.sass'
</style>
